import MainLayout from '../layouts/MainLayout';
import * as React from 'react';
import Helmet from 'react-helmet';

const AboutPage: React.FunctionComponent = () => (
  <MainLayout>
    <Helmet>
      <title>About</title>
    </Helmet>
  </MainLayout>
);

export default AboutPage;
